import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhatsAppButton from "./components/WhatsAppButton";
import { Helmet } from "react-helmet";
import logo from "./assets/logo.png";

// Lazy loading components
const HeroSection = lazy(() => import("./components/HeroSection"));
const AboutSection = lazy(() => import("./components/AboutSection"));
const FeaturedProducts = lazy(() => import("./components/FeaturedProducts"));
const SocialMediaFeed = lazy(() => import("./components/SocialMediaFeed"));
const Testimonials = lazy(() => import("./components/Testimonials"));
const About = lazy(() => import("./pages/About"));
const Vintage = lazy(() => import("./pages/Vintage"));
const Contact = lazy(() => import("./pages/Contact"));
const Booking = lazy(() => import("./components/Booking"));
const Rings = lazy(() => import("./pages/Rings"));
const Earrings = lazy(() => import("./pages/Earrings"));
const WeddingBands = lazy(() => import("./pages/WeddingBands"));
const Diamonds = lazy(() => import("./pages/Diamonds"));

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <Helmet>
          <title>Dream Jewels - Fine Jewelry and Engagement Rings</title>
          <meta
            name="description"
            content="Discover exquisite engagement rings, vintage collections, and luxury jewelry at Dream Jewels."
          />
          <meta
            name="keywords"
            content="jewelry, engagement rings, vintage, luxury, Dream Jewels"
          />
          <meta name="author" content="Dream Jewels" />
          {/* Open Graph Tags */}
          <meta
            property="og:title"
            content="Dream Jewels - Fine Jewelry and Engagement Rings"
          />
          <meta
            property="og:description"
            content="Explore our collection of fine jewelry, including engagement rings, vintage collections, and more."
          />
          <meta property="og:image" content="/path-to-your-image.jpg" />{" "}
          {/* Update with actual image path */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://dreamjewelsuk.com" />
          {/* Canonical Link */}
          <link rel="canonical" href="https://dreamjewelsuk.com" />
          {/* Structured Data (JSON-LD) */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "JewelryStore",
              name: "Dream Jewels",
              url: "https://dreamjewelsuk.com",
              logo: logo, // Update with actual logo path
              description:
                "Dream Jewels offers a collection of exquisite engagement rings, vintage jewelry, and luxury pieces.",
              address: {
                "@type": "PostalAddress",
                streetAddress: "23 Hatton Garden",
                addressLocality: "London",
                postalCode: "EC1N 8BQ",
                addressCountry: "UK",
              },
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+44 74665 55525",
                contactType: "Customer Service",
              },
              sameAs: [
                "https://www.facebook.com/dreamjewelsuk",
                "https://www.instagram.com/dreamjewelsuk",
              ],
            })}
          </script>
        </Helmet>

        <Navbar />
        <main className="flex-grow">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <HeroSection />
                    <AboutSection />
                    <FeaturedProducts />
                    <SocialMediaFeed />
                    <Testimonials />
                  </>
                }
              />
              <Route path="/about" element={<About />} />
              <Route path="/products/vintage" element={<Vintage />} />
              <Route path="/products/engagement-rings" element={<Rings />} />
              <Route
                path="/products/wedding-bands"
                element={<WeddingBands />}
              />
              <Route path="/products/diamonds" element={<Diamonds />} />
              <Route path="/products/accessories" element={<Earrings />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/booking" element={<Booking />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
        <WhatsAppButton />
      </div>
    </Router>
  );
}

export default App;
